import React from "react"
import { graphql, Link } from "gatsby"
import Layout from "../components/Layout"
import SingleImage from "../components/SingleImage"
import {
  Banner,
  TextWrapper,
  SectionTwo,
  SectionThree,
  ImagesWrapper,
} from "../styles/IndexStyles"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { library } from "@fortawesome/fontawesome-svg-core"
import {
  faMagic,
  faCode,
  faCoffee,
  fas,
} from "@fortawesome/free-solid-svg-icons"

import image01 from "../../static/waldfred.png"
import image02 from "../../static/pkwebsite.png"
import image03 from "../../static/voting-app.png"
import image04 from "../../static/thehub.jpeg"
import image05 from "../../static/greta.png"
import image06 from "../../static/art01.jpeg"

const projects = [
  {
    source: image01,
    title: "",
    link: "../waldfred",
    color: "white",
  },
  {
    source: image02,
    title: "PhilippKristian",
    link: "../philippKristian",
    color: "black",
  },
  {
    source: image03,
    title: "Voting- Die Qual der Wahl",
    link: "../../voting-app",
    color: "white",
  },
  {
    source: image04,
    title: "The Hub",
    link: "../the-hub",
    color: "white",
  },
  {
    source: image05,
    title: "Greta",
    link: "../greta",
    color: "white",
  },
  {
    source: image06,
    title: "Art",
    link: "../art",
    color: "black",
  },
]

library.add(faMagic, faCode, faCoffee, fas)

const index = () => (
  <Layout>
    <section style={{ position: "relative" }}>
      <Banner></Banner>
      <TextWrapper>
        <div>
          <h2>Eujurika</h2>
          <p>Hej, welcome to my Portfolio. I'm Jule.</p>
        </div>
      </TextWrapper>
    </section>
    <SectionTwo>
      <div>
        {/* <h2>My Passion</h2> */}
        <p>
          "Most good programmers do programming not because they expect to get
          paid,
          <br />
          but because it's fun to program."
          <h5>- Linus Torvalds</h5>
        </p>
      </div>
      <span>
        <FontAwesomeIcon
          icon="magic"
          color="white"
          size="2x"
          style={{ marginRight: "3rem" }}
          fixedWidth
        />
        <FontAwesomeIcon
          icon="coffee"
          color="white"
          size="2x"
          style={{ marginRight: "3rem" }}
          fixedWidth
        />
        <FontAwesomeIcon icon="code" color="white" size="2x" fixedWidth />
      </span>
    </SectionTwo>
    <SectionThree>
      <div>
        <ImagesWrapper>
          <SingleImage project={projects[0]} />
          <SingleImage project={projects[1]} />
        </ImagesWrapper>
        <ImagesWrapper>
          <SingleImage project={projects[2]} />
          <SingleImage project={projects[3]} />
        </ImagesWrapper>
        <ImagesWrapper>
          <SingleImage project={projects[4]} />
          <SingleImage project={projects[5]} />
        </ImagesWrapper>
      </div>
    </SectionThree>
  </Layout>
)

export default index

import { Link } from "gatsby"
import React from "react"
import styled from "styled-components"

import { GenericH2 } from "../styles/IndexStyles"

const ImgContainer = styled.div`
  position: relative;
  width: 100%;
  cursor: pointer;
`

const ImgMeta = styled.div`
  display: flex;
  align-items: flex-end;
  position: absolute;
  justify-content: flex-end;
  padding: 30px;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
`

const Img = styled.img`
  cursor: pointer;
  height: 100%;
  width: 100%;
  object-fit: cover;<
`

const SingleImage = props => {
  return (
    <ImgContainer>
      <Img src={props.project.source} />
      <Link to={props.project.link}>
        <ImgMeta>
          <GenericH2 bigger style={{ color: props.project.color }}>
            {props.project.title}
          </GenericH2>
        </ImgMeta>
      </Link>
    </ImgContainer>
  )
}

export default SingleImage
